
import Vue, { PropType } from "vue";

import BaseDialog from "@/components/dialog/BaseDialog.vue";
import { DialogCloseType, DialogI } from "@/types/dialog";
import Btn from "@/components/v2/base/Btn.vue";
import TextField from "../form/inputs/TextField.vue";

export default Vue.extend({
  name: "ReportBugDialog",
  components: {
    BaseDialog,
    Btn,
    TextField,
  },

  data() {
    return {
      userComment: "",
    };
  },

  methods: {
    submit() {
      this.dialog.close({ type: DialogCloseType.SUBMITTED, payload: this.userComment });
    },
    cancel() {
      this.dialog.close({ type: DialogCloseType.CLOSED });
    },
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
});
